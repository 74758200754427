import { MessageDiv, StyledArticle } from '../styles';

import { Layout } from '../components/Layout';
import React from 'react';
import { StyledH2 } from '../styles/common';
import { SEO } from '../components/SEO';
export const Head = () => (
	<SEO 
		title='Zdalna pomoc'
		description='Zdalna pomoc.'
	/>);
const ZdalnaPomocPage = () => (
	<Layout>
		<StyledArticle>
			<StyledH2 as="h1" color="info">
				Zdalna pomoc
			</StyledH2>
			<MessageDiv>
				<ul>
					<li>
						Klient VPN:{' '}
						<a href="https://api.mzk.bydgoszcz.pl/downloads/signed-Stormshield_SSLVPN_Client_2.8.0_en_x64.msi">
							signed-Stormshield_SSLVPN_Client_2.8.0_en_x64.msi
						</a>
					</li>
					<li>
						Zdalny pulpit:{' '}
						<a href="https://api.mzk.bydgoszcz.pl/downloads/TeamViewerQS.exe">
							TeamViewerQS.exe
						</a>
					</li>
				</ul>
			</MessageDiv>
		</StyledArticle>
	</Layout>
);

export default ZdalnaPomocPage;
